<template>
  <div class="main-wrap">
    <!-- 搜索 -->
    <search-form @search="search"></search-form>
    <!-- 角色列表 -->
    <perform-table :list="list" @record="showRecordDialog" @cond="showCondDialog"></perform-table>
    <!-- 分页管理 -->

     <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
    <!--推广详情-->
      <records
        @view="handleView"
        :is-show="showRecord"
        :promotion-id="showPromotionId"
        @cancel="showRecord = false"
        />
        <cond
        :is-show="showCond"
        :condData="condData"
        @cancel="showCond = false"
        />
  </div>
</template>

<script>
import searchForm from './components/search-form';
import performTable from './components/perform-table';
import service from './api';
import records from './components/records';
import cond from './components/cond';
import pagination from '@/mixins/pagination';

export default {
  data() {
    return {
      list: [],
      page: {},
      searchData: {},
      showRecord: false,
      showPromotionId: 0,
      showCond: false,
      condData: []
    };
  },
  mixins: [pagination],

  components: {
    searchForm,
    performTable,
    records,
    cond
  },
  methods: {
    init() {
      let initParams = { pageNum: 1 };
      this.getList(initParams);
    },
    showRecordDialog(id) {
      this.showPromotionId = id;
      this.showRecord = true;
    },
    showCondDialog(data){
      let arr = JSON.parse(data)
      this.condData = arr
      // console.log(data)

      this.showCond = true;
    },
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      return service.getList(params).then(res => {

        let page ={
          'allNum': res.total,
          'pageNum':res.pages,
          'pageSize': res.size
        }
        let list = res.records
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    // 筛选时间
    flatDate(list) {
      let dates = [];
      list.forEach(item => {
        dates.push(item.date);
      });
      dates = [...new Set(dates)];
      return dates;
    },
    search(data) {
        let params = Object.assign(data, { pageNum: 1 });
        this.searchData = data;
      return this.getList(params);
    }
  },
   mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
</style>
