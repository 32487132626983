import dayjs from "dayjs";

const toDayJs = d => typeof d == 'number' ? dayjs(d * 1000) : dayjs(d);

export default {
  methods: {
    // 格式化时间
    dateFormat(row, column) {
      var date = row[column.property];
      return date ? toDayJs(date).format("YYYY-MM-DD HH:mm:ss") : "无";
    },
    // 格式化时间
    simpleDateFormat(row, column) {
      var date = row[column.property];
      return date ? toDayJs(date).format("YYYY-MM-DD") : "无";
    },
    // 格式化时间
    notSecondDateFormat(row, column) {
      var date = row[column.property];
      return date ? toDayJs(date).format("YYYY-MM-DD HH:mm") : "无";
    },
    // 格式化时间
    notMinDateFormat(row, column) {
      var date = row[column.property];
      return date ? toDayJs(date).format("YYYY-MM-DD HH时") : "无";
    },
    randomEmoji() {
      const sets = [
        '🍇','🍈','🍉','🍊','🍋','🍌','🍍','🥭', '🍎','🍏','🍐','🍑','🍒','🍓','🥝','🍅','🥥','🥑','🍆','🥔','🥕','🌽',
        '🌶','🥒','🥬','🥦','🧄','🧅','🍄','🥜','🌰','🍞','🥐','🥖','🥨','🥯','🥞','🧇','🧀','🍖','🍗','🥩','🥓',
        '🍔','🍟','🍕','🌭','🥪','🌮','🌯','🥙','🧆','🥚','🍳','🥘','🍲','🥣','🥗','🍿','🧈','🧂','🥫','🍱','🍘','🍙',
        '🍚','🍛','🍜','🍝','🍠','🍢','🍣','🍤','🍥','🥮','🍡','🥟','🥠','🥡','🦪','🍦','🍧','🍨','🍩','🍪','🎂','🍰',
        '🧁','🥧','🍫','🍬','🍭','🍮','🍯','🍼','🥛','🍵','🍶','🍾','🍷','🍸','🍹','🍺','🍻','🥂','🥃','🥤','🧃','🧉',
        '🧊','🥢','🍽','🍴','🥄'
      ];
      return sets[Number.parseInt(Math.floor(Math.random() * sets.length))];
    },
  }
};
