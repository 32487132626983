import API from "@/api";

export default {
  getList: params => {
    return API.requestPost({
      url: "sponsored/promotionList",
      data:params,
      auth: true
    });
  },
  record: params => {
    return API.requestPost({
      url: "sponsored/ptRecord",
      data:params,
      auth: true
    });
  },
  
};
