<template>
  <div class="table-wrap">
    <el-table
      border
      :data="list"
      style="width: 100%"
      row-key="id"
      :tree-props="{children: 'children'}"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0' }"
    >
      <el-table-column prop="tempId" label="ID" align="center" width="50px" show-overflow-tooltip></el-table-column>
      <el-table-column prop="merchantName" label="商户名称" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="channelName" label="渠道名称" align="center"></el-table-column>
      <el-table-column prop="begin_at" label="开始时间" align="center" :formatter="notMinDateFormat" show-overflow-tooltip></el-table-column>
      <el-table-column prop="buy_count" align="center" label="计划数量" show-overflow-tooltip></el-table-column>
      <el-table-column prop="used_count" align="center" label="已推广人数"></el-table-column>
      <el-table-column prop="amount" align="center" label="消费星币">
        <template slot-scope="scope">
          {{ scope.row.amount/100 }}
        </template>
      </el-table-column>
      <el-table-column prop="statu" align="center" label="状态">
         <template slot-scope="scope">
            <span>{{ scope.row.status | statusFilter }}</span>
          </template>
      </el-table-column>
      <el-table-column prop="statu" align="center" label="操作">
        <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-search"
              circle
              @click="$emit('record', scope.row.id)"
          ></el-button>
           <el-button
              type="info"
              size="mini"
              icon="el-icon-mobile"
              circle
              @click="$emit('cond', scope.row.cond_description)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import format from '@/mixins/date.js';
export default {
  mixins: [format],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  filters: {
    statusFilter(status) {
      switch (status) {
        case -1:
          return '已取消';
        case 0:
          return '待确认';
        case 1:
          return '推广中';
        case 2:
          return '暂停推广';
        case 99:
          return '推广完成';

      }
    }
  },
  data() {
    return {};
  },
  methods:{
  }
};
</script>

<style lang="scss" scoped>
</style>
